<template>
  <v-card class="login">
    <v-card-text>
      <form>
        <h1>Login</h1>
        <form @submit="login">
          <v-text-field
            v-model="email"
            :error-messages="loginErrors"
            label="E-Mail"
            required
          ></v-text-field>
          <v-text-field
            @keyup.enter="login"
            v-model="password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            label="Passwort"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-btn
            class="mr-4"
            @click="login"
            color="success"
            :loading="loggingIn"
          >
            Anmelden
          </v-btn>
        </form>
      </form>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.login {
  max-width: 500px;
  margin: 0 auto;
}
</style>

<script>
export default {
  data() {
    return {
      loggingIn: false,
      email: "mail@zum-testen.online",
      password: "testzugang",
      showPassword: false,
      loginErrors: []
    };
  },
  mounted() {
    if (this.$route.query.verifizierung) {
      this.verifyEmail(this.$route.query.verifizierung);
    }
  },
  methods: {
    verifyEmail(url) {
      this.axios
        .get(url)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            "E-Mail-Adresse wurde verifiziert, Sie können sich jetzt anmelden"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "alerts/error",
            "E-Mail-Adresse konnte nicht verifiziert werden"
          );
        });
    },
    login() {
      this.loggingIn = true;
      try {
        this.$store
          .dispatch("login", {
            username: this.email,
            password: this.password
          })
          .then(() => {
            this.$store
              .dispatch("profile/me")
              .then(() => {
                this.$router.push("/aufzeichnungen");
              })
              .catch(() => {
                this.loggingIn = false;
              });
          })
          .catch(() => {
            this.loggingIn = false;
            this.loginErrors = ["Anmeldung fehlgeschlagen."];
          });
      } catch (e) {
        this.loggingIn = false;
        this.loginErrors = ["Anmeldung fehlgeschlagen."];
      }
    }
  }
};
</script>
